export const ocprojects = [
  {
    id: 1,
    name: "Booki",
    type: "OpenClassrooms",
    languages: ["CSS"],
    languagesIcons: ["fab fa-css3-alt"],
    source: "https://glg-dev.github.io/Booki/",
    repo: "https://github.com/glg-dev/Booki",
    description: `Intéger une maquette pour la société Booki, permettant de trouver des hébergements et activités dans une ville au choix, avec filtres pour les hébergements. Interface responsive.`,
    skills: ["Responsive Design"],
    picture: "./assets/img/Booki.png",
    filters: ["CSS/Sass"],
  },
  {
    id: 2,
    name: "Oh My Food",
    type: "OpenClassrooms",
    languages: ["Sass"],
    languagesIcons: ["fab fa-sass"],
    source: "https://glg-dev.github.io/OhMyFood/",
    repo: "https://github.com/glg-dev/OhMyFood",
    description: `Implémenter la version mobile d'un site de foodtech avec des animations CSS en assurant la cohérence graphique du site. Utilisation de Git pour le versioning.`,
    skills: ["Loader", "Animations"],
    picture: "./assets/img/OhMyFood.png",
    filters: ["CSS/Sass"],
  },
  {
    id: 3,
    name: "GameOn",
    type: "OpenClassrooms",
    languages: ["JavaScript", "CSS"],
    languagesIcons: ["fab fa-js", "fab fa-css3-alt"],
    source: "https://glg-dev.github.io/GameOn/",
    repo: "https://github.com/glg-dev/GameOn/",
    description: `Intégrer un formulaire fonctionnel et une fenêtre modale en JavaScript sur le site de GameOn, une entreprise de gaming`,
    skills: ["Formulaires"],
    picture: "./assets/img/GameOn.png",
    filters: ["JavaScript"],
  },
  {
    id: 4,
    name: "FishEye",
    type: "OpenClassrooms",
    languages: ["JavaScript", "CSS"],
    languagesIcons: ["fab fa-js", "fab fa-css3-alt"],
    source: "https://glg-dev.github.io/FishEye/",
    repo: "https://github.com/glg-dev/GameOn/",
    description: `Créer une plateforme destinée aux photographes freelance. Formulaire, carousel, fonctionnalités de tri, "likes", et accessibilité répondant aux recommandations WCAG, le tout maintenable.`,
    skills: ["Accessibilité"],
    picture: "./assets/img/FishEye.png",
    filters: ["JavaScript", "WCAG"],
  },
  {
    id: 5,
    name: "Les Petits Plats",
    type: "OpenClassrooms",
    languages: ["JavaScript", "CSS"],
    languagesIcons: ["fab fa-js", "fab fa-css3-alt"],
    source: "https://glg-dev.github.io/LesPetitsPlats/",
    repo: "https://github.com/glg-dev/LesPetitsPlats/",
    description: `Créer des algorithmes de recherche de recettes selon plusieurs paradigmes avec possibilité de trier selon différents critères, tests de performance de ces algorithmes, gestion de l'asynchrone.`,
    skills: ["Algorithmes", "Tests de performance"],
    picture: "./assets/img/LesPetitsPlats.png",
    filters: ["JavaScript"],
  },
  {
    id: 6,
    name: "Billed",
    type: "OpenClassrooms",
    languages: ["JavaScript"],
    languagesIcons: ["fab fa-js"],
    repo: "https://github.com/glg-dev/Billed/",
    description: `Débugger l'application de gestion de note de frais Billed, et implémenter des tests unitaires et d'intégration.`,
    skills: ["Tests"],
    picture: "./assets/img/Billed.png",
    filters: ["JavaScript", "API"],
  },
  // {
  //   id: 7,
  //   name: 'Learn@Home',
  // type: 'OpenClassrooms',
  //   description: `Créer une maquette d'une plateforme permettant aux élèves et leurs tuteurs de communiquer, et indiquer les userstories ainsi que les diagrammes de cas d'usage.`,
  //   skills: ['Figma', 'User stories', 'Diagrammes de cas d\'usage', 'Kanban', 'notion.so'],
  //   picture: './assets/img/Learn@Home.png',
  //  filters : ['Figma', 'Notion.so', 'Gestion de projet]
  // },
  {
    id: 8,
    name: "Kasa",
    type: "OpenClassrooms",
    languages: ["React", "Sass"],
    languagesIcons: ["fab fa-react", "fab fa-sass"],
    repo: "https://github.com/glg-dev/Kasa",
    description: `Implémenter le front-end de Kasa, une application de location de logements, avec React et React-Router.`,
    skills: ["React", "React Router"],
    picture: "./assets/img/Kasa.png",
    filters: ["CSS/Sass", "React", "API"],
  },
  {
    id: 9,
    name: "SportSee",
    type: "OpenClassrooms",
    languages: ["React", "Sass"],
    languagesIcons: ["fab fa-react", "fab fa-sass"],
    repo: "https://github.com/glg-dev/SportSee",
    description: `Développer un tableau de bord d'analytics avec graphiques et diagrammes à l'aide d'une librairie, pour une startup dédiée au coaching sportif. Générer la documentation technique de l'application.`,
    skills: ["Librairies React", "Tableau de bord"],
    picture: "./assets/img/SportSee.png",
    filters: ["CSS/Sass", "React", "API"],
  },
  {
    id: 10,
    name: "Argent Bank",
    type: "OpenClassrooms",
    languages: ["React", "CSS"],
    languagesIcons: ["fab fa-react", "fab fa-css3-alt"],
    repo: "https://github.com/glg-dev/argent-bank",
    description: `Créer une application web permettant de gérer les comptes bancaires d'un client. Implémenter un gestionnaire d'état, s'authentifier à une API, intéragir avec et la modéliser.`,
    skills: ["Redux", "TailwindCSS", "API"],
    picture: "./assets/img/ArgentBank.png",
    filters: ["React", "TailwindCSS", "API"],
  },
  {
    id: 11,
    name: "HRNet",
    type: "OpenClassrooms",
    languages: ["React", "Sass"],
    languagesIcons: ["fab fa-react", "fab fa-sass"],
    source: "https://hrnet-beta.vercel.app/",
    repo: "https://github.com/glg-dev/hrnet",
    description: `Convertir une app de gestion de dossiers des employés depuis jQuery vers React, pour une société financière, moderniser le style et créer un plugin React de zéro pour le publier sur NPM.`,
    skills: ["Redux", "Création de librairies"],
    picture: "./assets/img/HRNet.png",
    filters: ["React", "npm"],
  },
];
