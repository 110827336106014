export const persoProjects = [
  {
    id: 1,
    name: "Netflix Clone",
    type: "Perso",
    languages: ["React", "Sass"],
    languagesIcons: ["fab fa-react", "fab fa-sass"],
    source: "https://netflix-clone-glg.vercel.app/",
    repo: "https://github.com/glg-dev/netflix-clone",
    description: `Clone de la plateforme Netflix à l'aide de React et de l'API TMDB.`,
    skills: ["React", "API", "Axios"],
    picture: "./assets/img/NetflixClone.png",
    filters: ["React", "CSS/Sass", "API"],
  },
  {
    id: 2,
    name: "React Clocks",
    type: "Perso",
    languages: ["React", "Sass"],
    languagesIcons: ["fab fa-react", "fab fa-sass"],
    source: "https://react-clocks.vercel.app/",
    repo: "https://github.com/glg-dev/react-clocks",
    description: `Horloge sur laquelle on peut cliquer pour qu'elle affiche l'heure au format numérique. Possibilité de changer le thème couleur de la page.`,
    skills: ["React", "Redux"],
    picture: "./assets/img/ReactClocks.png",
    filters: ["CSS/Sass", "React"],
  },
  {
    id: 3,
    name: "Playground",
    type: "Perso",
    languages: ["React", "Sass"],
    languagesIcons: ["fab fa-react", "fab fa-sass"],
    source: "https://playground-glg-dev.vercel.app/",
    repo: "https://github.com/glg-dev/bac-a-sable",
    description: `Diverses application : générateur de ciel étoilé, mini-jeux (morpion, pierre-feuille-ciseaux), générateur de mot de passe, animation 3D, simulateur de batterie.`,
    skills: ["React"],
    picture: "./assets/img/Playground.png",
    filters: ["CSS/Sass", "React"],
  },
  {
    id: 4,
    name: "CSS Variables",
    type: "Perso",
    languages: ["JavaScript", "CSS"],
    languagesIcons: ["fab fa-js", "fab fa-css3-alt"],
    source: "https://glg-dev.github.io/CSS-Variables/",
    repo: "https://github.com/glg-dev/CSS-Variables",
    description: `Possibilité de changer les règles CSS d'une image ( taille et couleur de la bordure, flou, rotation, taille) au clic, grâce à JavaScript.`,
    skills: ["setProperty", "addEventListener"],
    picture: "./assets/img/CSSVariables.png",
    filters: ["JavaScript"],
  },
];
